import React from 'react'
import Layout from '../components/Layout'

export default function Home() {
  return(
      <Layout>
          <div>HOME PAGE</div>  
      </Layout>

  )
}
